import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Seo from "../components/SEO"
import Layout from '../components/layout'

import '../ui/blog.css'

import logoFacebook from '../images/social-media/facebook.png'
import logoTwitter from '../images/social-media/twitter.png'


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
  const post = {
    title: frontmatter.metatitle,
    description: frontmatter.metadescription,
    image: frontmatter.thumbnail ? frontmatter.thumbnail  : 'https://artimouss.ch/assets/images/logo/big.png',
    author: 'Bruno Santos Lopes'
  }


  const socialText = encodeURIComponent(frontmatter.title)
  const socialLink = encodeURIComponent(site.siteMetadata.url + frontmatter.path)

  const twitterLink = "https://twitter.com/share?text="+socialText+"&url=" + socialLink + "&via=ArtiMouss"
  const facebookLink = "https://www.facebook.com/sharer/sharer.php?u=" + socialLink

  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image} post={post} />
    <Layout>
        <div className='main'>
            <div className='main-content'>
                <div className='main-blog'>
                    <div className="blog-content">
                        <div className="blog-header">
                            <p className="fil-arianne"><Link alt="Page d'accueil" to="/">Accueil</Link> - <Link alt="blog sur les bombes de bain" to="/blog">Blog</Link> - {frontmatter.title}</p>
                            <p className="blog-date">{frontmatter.date} - {post.author}</p>
                            <h1>{frontmatter.title}</h1>
                            <p>{frontmatter.introduction}</p>
                            {
                              frontmatter.thumbnail ? 
                                <img width="300px" height="300px" alt={frontmatter.title} src={frontmatter.thumbnail} />
                              : ''
                            }

                        </div>
                        <div
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                        <div class="social-media-share">
                            <a target="_blank" href={twitterLink}>
                              <img alt="twitter icon" src={logoTwitter} />
                            </a>
                            <a  target="_blank" href={facebookLink}>
                              <img alt="facebook icon" src={logoFacebook} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
  
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metatitle
        metadescription
        path
        introduction
        thumbnail
      }
      
    },
    site {
      siteMetadata {
        url
      }
    }
  }
`